export function Panel(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg fill='none' viewBox='0 0 301 461' aria-hidden {...props}>
      <g filter='url(#filter0_d_35_2)'>
        <path
          fill='#fff'
          d='m4.705 141.252 36.53-37.442a6.002 6.002 0 0 1 4.294-1.81h102.409a6.002 6.002 0 0 0 4.334-1.851l91.581-95.673a6 6 0 0 1 4.334-1.851H292a6 6 0 0 1 6 6V452a6 6 0 0 1-6 6H9a6 6 0 0 1-6-6V145.442a6 6 0 0 1 1.705-4.19Z'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_35_2'
          width='300'
          height='460.375'
          x='.5'
          y='.125'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='1.25' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_35_2' />
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_35_2'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
